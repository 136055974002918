/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/themes/bnc-fraude.module.css';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {
};

function BncFraudeTheme({ children }) {
    return <div className={styles.container}>{children}</div>;
}

BncFraudeTheme.propTypes = propTypes;
BncFraudeTheme.defaultProps = defaultProps;

export default BncFraudeTheme;
